import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import {
  formatBand,
  formatEvent,
  formatExperienceTransactionsGroupedForGig,
  formatGig,
  formatGigExperience,
  formatMerchandise,
  formatMerchandiseTransaction,
  formatResetSong,
  formatSetlist,
  formatShoutout,
  formatTip,
  formatTipsGroupedForGig,
} from "../services/controllers/helperFunctions";

const sharedModule = require("../shared.js").default;

let { socket } = sharedModule;

Vue.use(Vuex);

const persistentStore = new Vuex.Store({
  state: {
    currentlyLoggedInBand: null,
    disableProfileTextNotificationsStep: false,
    uniqueId: null,
    customerEmail: null,
    customerName: null,
    selectedCurrency: {
      value: "USD",
      text: "USD",
      name: "United States Dollar",
      symbol: "$",
    },
    localSongPasses: [],
    userVotingForGigs: {},
    // kidzbop over 18
    hasBeenAskedForAge: false,
    isOver18: false,
    optIntoEmails: true,
  },
  mutations: {
    setCustomerName(state, value) {
      state.customerName = value;
    },
    setCurrentlyLoggedInBand(state, band) {
      const stringifiedBand = JSON.stringify(Object.assign({}, band));
      state.currentlyLoggedInBand = stringifiedBand;
    },
    setDisableProfileTextNotificationsStep(state, value) {
      state.disableProfileTextNotificationsStep = value;
    },
    setUniqueId(state, value) {
      state.uniqueId = value;
    },
    setCustomerEmail(state, value) {
      state.customerEmail = value;
    },
    setSelectedCurrency(state, value) {
      state.selectedCurrency = value;
    },
    setLocalSongPasses(state, value) {
      state.localSongPasses = value;
    },
    reportTipAtGig(state, gigId) {
      if (!state.userVotingForGigs[gigId]) {
        state.userVotingForGigs[gigId] = {
          numVotes: 1,
        };
      } else {
        state.userVotingForGigs[gigId].numVotes += 1;
      }
    },
    setIsOver18(state, value) {
      state.isOver18 = value;
      state.hasBeenAskedForAge = true;
    },
    setOptIntoEmails(state, value) {
      state.optIntoEmails = value;
    },
  },
  getters: {
    currentlyLoggedInBand: (state) =>
      state.currentlyLoggedInBand
        ? JSON.parse(state.currentlyLoggedInBand)
        : undefined,
  },
  plugins: [createPersistedState()],
});

const nonPersistentStore = new Vuex.Store({
  state: {
    // customer email
    customerEmail: null,
    // customer name
    customerName: null,
    // unique id for the user associated with this browser,
    uniqueId: null,
    // currently logged in firebase user
    currentFirebaseUser: null,
    // currently logged in band
    currentlyLoggedInBand: undefined,
    // current user from database
    currentUser: undefined,
    // returns if the current user is an admin
    isAdmin: false,
    // the selected band that is being backdoored into
    otherBand: undefined,
    // controls whether or not ads are being shown in the current session
    displayAds: true,
    // controls whether or not the user has seen up to a certain point in the onboarding
    disableProfileTextNotificationsStep: false,
    // image upload progress
    imageUploadProgress: 0,
    // the local song passes that are stored
    localSongPasses: [],
    // current gig stats
    userVotingForGigs: {},
    // kidzbop over 18
    hasBeenAskedForAge: false,
    isOver18: false,
    optIntoEmails: false,
    // states that are tied to subscriptions
    // these are the snapshot states that get updated
    currentBand: undefined,
    currentGig: undefined,
    currentSetlist: undefined,
    currentSetlistWithGigMetadata: undefined,
    currentTips: undefined,
    currentTipsGroupedForGig: undefined,
    currentEvent: undefined,
    currentEventTransactions: undefined,
    currentSongResets: undefined,
    currentShoutoutsAuth: undefined,
    currentShoutoutsSafe: undefined,
    currentGigExperiences: undefined,
    currentGigExperiencesTransactions: undefined,
    currentMerchandiseForBand: undefined,
    currentMerchandiseTransactions: undefined,
    currentMerchandise: undefined,
    currentSongBattleTransactions: undefined,
    currentLivecastState: undefined,
    currentGigEvents: undefined,
    // these are the actual subscriptions
    bandSubscription: undefined,
    gigSubscription: undefined,
    setlistSubscription: undefined,
    setlistSubscriptionWithGigMetadata: undefined,
    tipsSubscription: undefined,
    songResetsSubscription: undefined,
    merchandiseSubscription: undefined,
    // new types of subscriptions
    tipsGroupedForGigSubscription: {
      lastUpdateTime: undefined,
      subscription: undefined,
      interval: undefined,
    },
    currentShoutoutsAuthSubscription: {
      lastUpdateTime: undefined,
      subscription: undefined,
      interval: undefined,
    },
    currentShoutoutsSafeSubscription: {
      lastUpdateTime: undefined,
      subscription: undefined,
      interval: undefined,
    },
    currentGigExperiencesSubscription: {
      lastUpdateTime: undefined,
      subscription: undefined,
      interval: undefined,
    },
    currentGigExperiencesTransactionsSubscription: {
      lastUpdateTime: undefined,
      subscription: undefined,
      interval: undefined,
    },
    currentMerchandiseSubscription: {
      lastUpdateTime: undefined,
      subscription: undefined,
      interval: undefined,
    },
    currentMerchandiseTransactionsSubscription: {
      lastUpdateTime: undefined,
      subscription: undefined,
      interval: undefined,
    },
    currentSongBattleTransactionsSubscription: {
      lastUpdateTime: undefined,
      subscription: undefined,
      interval: undefined,
    },
    currentLivecastStateSubscription: {
      lastUpdateTime: undefined,
      subscription: undefined,
      interval: undefined,
    },
    eventSubscription: {
      lastUpdateTime: undefined,
      subscription: undefined,
      interval: undefined,
    },
    eventTransactionsSubscription: {
      lastUpdateTime: undefined,
      subscription: undefined,
      interval: undefined,
    },
    gigEventsSubscription: {
      lastUpdateTime: undefined,
      subscription: undefined,
      interval: undefined,
    },
    // currency options,
    selectedCurrency: {
      value: "USD",
      text: "USD",
      name: "United States Dollar",
      symbol: "$",
    },
    currencyOptions: [
      { value: "USD", text: "USD", name: "United States Dollar", symbol: "$" },
      { value: "CAD", text: "CAD", name: "Canadian Dollar", symbol: "C$" },
    ],
    needToDetermineStep: true,
  },
  getters: {
    disableProfileTextNotificationsStep: () =>
      persistentStore.state.disableProfileTextNotificationsStep,
    displayAds: (state) => state.displayAds,
    isLoggedIn: (state) => !!state.currentFirebaseUser,
    isAdmin: (state) => state.isAdmin,
    songs: (state) => state.songs,
    setlists: (state) => state.setlists,
    bandPageName: (state) => {
      return state.bandPageName;
    },
  },
  mutations: {
    setIsOver18(state, value) {
      state.isOver18 = value;
      state.hasBeenAskedForAge = true;
      persistentStore.commit("setIsOver18", value);
    },
    setOptIntoEmails(state, value) {
      state.optIntoEmails = value;
      persistentStore.commit("setOptIntoEmails", value);
    },
    setLocalSongPasses(state, value) {
      state.localSongPasses = value;
      persistentStore.commit("setLocalSongPasses", value);
    },
    setImageUploadProgress(state, value) {
      state.imageUploadProgress = value;
    },
    setNeedToDetermineStep(state, value) {
      state.needToDetermineStep = value;
    },
    // unique identifing properties
    setCustomerEmail(state, value) {
      state.customerEmail = value;
      persistentStore.commit("setCustomerEmail", value);
    },
    setUniqueId(state, id) {
      state.uniqueId = id;
      persistentStore.commit("setUniqueId", id);
    },
    setCustomerName(state, value) {
      state.customerName = value;
      persistentStore.commit("setCustomerName", value);
    },
    // selected band for backdoor
    setOtherBand(state, other) {
      state.otherBand = other;
    },
    // socket based fun :)
    // bands
    addOrUpdateBandData(state, value) {
      const { ID } = value;

      if (ID == state?.setCurrentlyLoggedInBand?.ID) {
        this.commit("setCurrentlyLoggedInBand", value);
      }

      const objectKeys = Object.keys(value);
      if (!state.currentBand) {
        state.currentBand = value;
        this.commit("addBandSubscription", ID);
      } else if (state.currentBand.ID === value.ID) {
        for (const key of objectKeys) {
          state.currentBand[key] = value[key];
        }
      } else {
        state.currentBand = value;
        this.commit("addBandSubscription", ID);
      }
    },
    addBandSubscription(state, ID) {
      if (state.bandSubscription) {
        socket.off(state.bandSubscription);
      }
      const idStr = `BAND-${ID}`;
      state.bandSubscription = idStr;
      socket.on(idStr, (data) => {
        this.commit("addOrUpdateBandData", formatBand(data));
      });
    },
    // gigs
    addOrUpdateGigData(state, value) {
      const { ID } = value;
      const objectKeys = Object.keys(value);
      // set currentGig
      if (!state.currentGig) {
        state.currentGig = value;
        this.commit("addGigSubscription", ID);
      } else if (state.currentGig.ID === value.ID) {
        for (const key of objectKeys) {
          state.currentGig[key] = value[key];
        }
      } else {
        state.currentGig = value;
        this.commit("addGigSubscription", ID);
      }
    },
    addGigSubscription(state, ID) {
      if (state.gigSubscription) {
        socket.off(state.gigSubscription);
      }
      const idStr = `GIG-${ID}`;
      state.gigSubscription = idStr;
      socket.on(idStr, (data) => {
        this.commit("addOrUpdateGigData", formatGig(data));
      });
    },
    // setlists
    addOrUpdateSetlistData(state, value) {
      const { ID } = value;
      if (!state.currentSetlist) {
        state.currentSetlist = value;
        this.commit("addSetlistSubscription", ID);
      } else if (state.currentSetlist.ID === value.ID) {
        state.currentSetlist = value;
      } else {
        state.currentSetlist = value;
        this.commit("addSetlistSubscription", ID);
      }
    },
    addSetlistSubscription(state, ID) {
      if (state.setlistSubscription) {
        socket.off(state.setlistSubscription);
      }
      const idStr = `SETLIST-${ID}`;
      state.setlistSubscription = idStr;
      socket.on(idStr, (data) => {
        const formattedSetlist = formatSetlist(data);
        this.commit("addOrUpdateSetlistData", formattedSetlist);
      });
    },
    addOrUpdateSetlistWithGigMetadata(state, object) {
      const { value, gigId } = object;
      const { ID } = value;
      const objectKeys = Object.keys(value);
      if (!state.currentSetlistWithGigMetadata) {
        state.currentSetlistWithGigMetadata = object;
        this.commit("addSetlistWithGigMetadataSubscription", { gigId });
      } else if (state.currentSetlistWithGigMetadata.value.ID === ID) {
        for (const key of objectKeys) {
          state.currentSetlistWithGigMetadata.value[key] = value[key];
        }
      } else {
        state.currentSetlistWithGigMetadata = object;
        this.commit("addSetlistWithGigMetadataSubscription", { gigId });
      }
    },
    addSetlistWithGigMetadataSubscription(state, obj) {
      const { gigId } = obj;
      if (state.setlistSubscriptionWithGigMetadata) {
        socket.off(state.setlistSubscriptionWithGigMetadata);
      }
      const idStr = `SETLIST-GIG-${gigId}`;
      state.setlistSubscriptionWithGigMetadata = idStr;
      socket.on(idStr, (data) => {
        this.commit("addOrUpdateSetlistWithGigMetadata", {
          value: formatSetlist(data),
          gigId,
        });
      });
    },
    // tips
    addOrUpdateTipsData(state, object) {
      const { gigId } = object;
      if (!state.currentTips) {
        state.currentTips = object;
        this.commit("addTipsSubscription", gigId);
      } else if (state.currentTips.gigId === gigId) {
        state.currentTips = object;
      } else {
        state.currentTips = object;
        this.commit("addTipsSubscription", gigId);
      }
    },
    addTipsSubscription(state, ID) {
      if (state.tipsSubscription) {
        socket.off(state.tipsSubscription);
      }
      const idStr = `TIPS-${ID}`;
      state.tipsSubscription = idStr;
      socket.on(idStr, (data) => {
        this.commit("addOrUpdateTipsData", {
          values: data ? data.map(formatTip) : [],
          gigId: ID,
        });
      });
    },
    addOrUpdateTipsGroupedForGigData(state, value) {
      const { gigId } = value;
      // no current values at all so set it and create subscription
      if (!state.currentTipsGroupedForGig) {
        state.currentTipsGroupedForGig = value;
        this.commit("addTipsGroupedForGigSubscription", gigId);
        // same gigId so just update the values
      } else if (state.currentTipsGroupedForGig.gigId === gigId) {
        state.currentTipsGroupedForGig = value;
      } else {
        // different gigId so set it and create subscription
        state.currentTipsGroupedForGig = value;
        this.commit("addTipsGroupedForGigSubscription", gigId);
      }
      state.tipsGroupedForGigSubscription.lastUpdateTime = new Date();
    },
    addTipsGroupedForGigSubscription(state, ID) {
      // clear previous subscription and intervals
      if (state.tipsGroupedForGigSubscription.subscription) {
        socket.off(state.tipsGroupedForGigSubscription.subscription);
      }
      if (state.tipsGroupedForGigSubscription.interval) {
        clearInterval(state.tipsGroupedForGigSubscription.interval);
      }
      // set up new subscription
      const idStr = `TIPS-GROUPED-${ID}`;
      state.tipsGroupedForGigSubscription.subscription = idStr;
      socket.on(idStr, (data) => {
        // update actual date in state when new data arrives
        this.commit(
          "addOrUpdateTipsGroupedForGigData",
          formatTipsGroupedForGig(data)
        );
        // record the last time the update occured
        state.tipsGroupedForGigSubscription.lastUpdateTime = new Date();
      });
      // set up interval
      const localThis = this;
      const tipsInterval = setInterval(async () => {
        const now = new Date();
        const differenceinTime =
          now - state.tipsGroupedForGigSubscription.lastUpdateTime;
        // if time since last update is less than 10 seconds, don't update
        if (differenceinTime >= 9 * 1000) {
          // fetch new data
          const {
            getLiveSongRequestsForGig,
          } = require("../services/controllers/tipController");
          const groupedTips = await getLiveSongRequestsForGig(ID, true);
          // update state with new data
          localThis.commit("addOrUpdateTipsGroupedForGigData", groupedTips);
          // record the last time the update occured
          state.tipsGroupedForGigSubscription.lastUpdateTime = new Date();
        }
      }, 10 * 1000);
      state.tipsGroupedForGigSubscription.interval = tipsInterval;
    },
    // events
    addOrUpdateEventData(state, value) {
      const { ID } = value;
      // no current values at all so set it and create subscription
      if (!state.currentEvent) {
        state.currentEvent = value;
        this.commit("addEventSubscription", ID);
        // same ID so just update the values
      } else if (state.currentEvent.ID === ID) {
        state.currentEvent = value;
      } else {
        // different ID so set it and create subscription
        state.currentEvent = value;
        this.commit("addEventSubscription", ID);
      }
      state.eventSubscription.lastUpdateTime = new Date();
    },
    addEventSubscription(state, ID) {
      // clear previous subscription and intervals
      if (state.eventSubscription.subscription) {
        socket.off(state.eventSubscription.subscription);
      }
      if (state.eventSubscription.interval) {
        clearInterval(state.eventSubscription.interval);
      }
      // set up new subscription
      const idStr = `EVENT-${ID}`;
      state.eventSubscription.subscription = idStr;
      socket.on(idStr, (data) => {
        // update actual date in state when new data arrives
        this.commit("addOrUpdateEventData", formatEvent(data));
        state.eventSubscription.lastUpdateTime = new Date();
      });
      // set up interval
      const localThis = this;
      const eventInterval = setInterval(async () => {
        const now = new Date();
        const differenceinTime = now - state.eventSubscription.lastUpdateTime;
        // if time since last update is less than 10 seconds, don't update
        if (differenceinTime >= 9 * 1000) {
          // fetch new data
          const {
            getEvent,
          } = require("../services/controllers/eventController");
          const event = await getEvent({ eventId: ID, forceRefresh: true });
          // update state with new data
          localThis.commit("addOrUpdateEventData", event);
          // record the last time the update occured
          state.eventSubscription.lastUpdateTime = new Date();
        }
      }, 10 * 1000);
      state.eventSubscription.interval = eventInterval;
    },
    addOrUpdateEventTransactionsData(state, value) {
      const { gigId } = value;
      // no current value at all so set it and create subscription
      if (!state.currentEventTransactions) {
        state.currentEventTransactions = value;
        this.commit("addEventTransactionsSubscription", gigId);
        // same gigId so just update the value
      } else if (state.currentEventTransactions.gigId === gigId) {
        state.currentEventTransactions = value;
      } else {
        // different gigId so set it and create subscription
        state.currentEventTransactions = value;
        this.commit("addEventTransactionsSubscription", gigId);
      }
      state.eventTransactionsSubscription.lastUpdateTime = new Date();
    },
    addEventTransactionsSubscription(state, gigId) {
      // clear previous subscription and intervals
      if (state.eventTransactionsSubscription.subscription) {
        socket.off(state.eventTransactionsSubscription.subscription);
      }
      if (state.eventTransactionsSubscription.interval) {
        clearInterval(state.eventTransactionsSubscription.interval);
      }
      // set up new subscription
      const idStr = `EVENT-TRANSACTIONS-${gigId}`;
      state.eventTransactionsSubscription.subscription = idStr;
      socket.on(idStr, (data) => {
        // update actual date in state when new data arrives
        this.commit("addOrUpdateEventTransactionsData", {
          gigId,
          value: data,
        });
        state.eventTransactionsSubscription.lastUpdateTime = new Date();
      });
      // set up interval
      const localThis = this;
      const eventInterval = setInterval(async () => {
        const now = new Date();
        const differenceinTime =
          now - state.eventTransactionsSubscription.lastUpdateTime;
        // if time since last update is less than 10 seconds, don't update
        if (differenceinTime >= 9 * 1000) {
          // fetch new data
          const {
            getAllEventTransactions,
          } = require("../services/controllers/eventController");
          const eventTransactions = await getAllEventTransactions(gigId, true);
          // update state with new data
          localThis.commit("addOrUpdateEventTransactionsData", {
            gigId,
            value: eventTransactions,
          });
          // record the last time the update occured
          state.eventTransactionsSubscription.lastUpdateTime = new Date();
        }
      }, 10 * 1000);
      state.eventTransactionsSubscription.interval = eventInterval;
    },
    // song resets
    addOrUpdateSongResetsData(state, object) {
      const { gigId } = object;
      if (!state.currentSongResets) {
        state.currentSongResets = object;
        this.commit("addSongResetsSubscription", gigId);
      } else if (state.currentSongResets.gigId === gigId) {
        state.currentSongResets = object;
        this.commit("addSongResetsSubscription", gigId);
      } else {
        state.currentSongResets = object;
        this.commit("addSongResetsSubscription", gigId);
      }
    },
    addSongResetsSubscription(state, ID) {
      if (state.songResetsSubscription) {
        socket.off(state.songResetsSubscription);
      }
      const idStr = `RESET_SONGS-${ID}`;
      state.songResetsSubscription = idStr;
      socket.on(idStr, (data) => {
        this.commit("addOrUpdateSongResetsData", {
          gigId: ID,
          values: data.map(formatResetSong),
        });
      });
    },
    // users
    addOrUpdateUserData(state, value) {
      state.currentUser = value;
      state.isAdmin = value?.isAdmin;
    },
    // shoutouts
    addOrUpdateShoutoutsSafeData(state, value) {
      const { gigId } = value;
      // no current values at all so set it and create subscription
      if (!state.currentShoutoutsSafe) {
        state.currentShoutoutsSafe = value;
        this.commit("addShoutoutsSafeSubscription", gigId);
        // same gigId so just update the values
      } else if (state.currentShoutoutsSafe.gigId === gigId) {
        state.currentShoutoutsSafe = value;
      } else {
        // different gigId so set it and create subscription
        state.currentShoutoutsSafe = value;
        this.commit("addShoutoutsSafeSubscription", gigId);
      }
      state.currentShoutoutsSafeSubscription.lastUpdateTime = new Date();
    },
    addShoutoutsSafeSubscription(state, ID) {
      // clear previous subscription and intervals
      if (state.currentShoutoutsSafeSubscription.subscription) {
        socket.off(state.currentShoutoutsSafeSubscription.subscription);
      }
      if (state.currentShoutoutsSafeSubscription.interval) {
        clearInterval(state.currentShoutoutsSafeSubscription.interval);
      }
      // set up new subscription
      const idStr = `SHOUTOUTS-${ID}`;
      state.currentShoutoutsSafeSubscription.subscription = idStr;
      socket.on(idStr, (data) => {
        // update actual date in state when new data arrives
        this.commit("addOrUpdateShoutoutsSafeData", {
          gigId: ID,
          values: data ? data.map(formatShoutout) : [],
        });
        // record the last time the update occured
        state.currentShoutoutsSafeSubscription.lastUpdateTime = new Date();
      });
      // set up interval
      const localThis = this;
      const tipsInterval = setInterval(async () => {
        const now = new Date();
        const differenceinTime =
          now - state.currentShoutoutsSafeSubscription.lastUpdateTime;
        // if time since last update is less than 10 seconds, don't update
        if (differenceinTime >= 9 * 1000) {
          // fetch new data
          const {
            getShoutouts,
          } = require("../services/controllers/shoutoutsController");
          const shoutouts = await getShoutouts({
            gigId: ID,
            forceRefresh: true,
          });
          // update state with new data
          localThis.commit("addOrUpdateShoutoutsSafeData", {
            gigId: ID,
            values: shoutouts,
          });
          // record the last time the update occured
          state.currentShoutoutsSafeSubscription.lastUpdateTime = new Date();
        }
      }, 10 * 1000);
      state.currentShoutoutsSafeSubscription.interval = tipsInterval;
    },
    addOrUpdateShoutoutsAuthData(state, value) {
      const { gigId } = value;
      // no current values at all so set it and create subscription
      if (!state.currentShoutoutsAuth) {
        state.currentShoutoutsAuth = value;
        this.commit("addShoutoutsAuthSubscription", gigId);
        // same gigId so just update the values
      } else if (state.currentShoutoutsAuth.gigId === gigId) {
        state.currentShoutoutsAuth = value;
      } else {
        // different gigId so set it and create subscription
        state.currentShoutoutsAuth = value;
        this.commit("addShoutoutsAuthSubscription", gigId);
      }
      state.currentShoutoutsAuthSubscription.lastUpdateTime = new Date();
    },
    addShoutoutsAuthSubscription(state, ID) {
      // clear previous subscription and intervals
      if (state.currentShoutoutsAuthSubscription.subscription) {
        socket.off(state.currentShoutoutsAuthSubscription.subscription);
      }
      if (state.currentShoutoutsAuthSubscription.interval) {
        clearInterval(state.currentShoutoutsAuthSubscription.interval);
      }
      // set up new subscription
      const idStr = `SHOUTOUTS-AUTH-${ID}`;
      state.currentShoutoutsAuthSubscription.subscription = idStr;
      socket.on(idStr, (data) => {
        // update actual date in state when new data arrives
        this.commit("addOrUpdateShoutoutsAuthData", {
          gigId: ID,
          values: data ? data.map(formatShoutout) : [],
        });
        // record the last time the update occured
        state.currentShoutoutsAuthSubscription.lastUpdateTime = new Date();
      });
      // set up interval
      const localThis = this;
      const tipsInterval = setInterval(async () => {
        const now = new Date();
        const differenceinTime =
          now - state.currentShoutoutsAuthSubscription.lastUpdateTime;
        // if time since last update is less than 10 seconds, don't update
        if (differenceinTime >= 9 * 1000) {
          // fetch new data
          const {
            getShoutouts,
          } = require("../services/controllers/shoutoutsController");
          const shoutouts = await getShoutouts({
            gigId: ID,
            forceRefresh: true,
            needsAuthValues: true,
          });
          // update state with new data
          localThis.commit("addOrUpdateShoutoutsAuthData", {
            gigId: ID,
            values: shoutouts,
          });
          // record the last time the update occured
          state.currentShoutoutsAuthSubscription.lastUpdateTime = new Date();
        }
      }, 10 * 1000);
      state.currentShoutoutsAuthSubscription.interval = tipsInterval;
    },
    // gig experiences
    addOrUpdateGigExperiencesData(state, value) {
      const { gigId, bandId } = value;
      // no current values at all so set it and create subscription
      if (!state.currentGigExperiences) {
        state.currentGigExperiences = value;
        this.commit("addGigExperiencesSubscription", { ID: gigId, bandId });
        // same gigId so just update the values
      } else if (state.currentGigExperiences.gigId === gigId) {
        state.currentGigExperiences = value;
      } else {
        // different gigId so set it and create subscription
        state.currentGigExperiences = value;
        this.commit("addGigExperiencesSubscription", { ID: gigId, bandId });
      }
      state.currentGigExperiencesSubscription.lastUpdateTime = new Date();
    },
    addGigExperiencesSubscription(state, obj) {
      const { ID, bandId } = obj;
      // clear previous subscription and intervals
      if (state.currentGigExperiencesSubscription.subscription) {
        socket.off(state.currentGigExperiencesSubscription.subscription);
      }
      if (state.currentGigExperiencesSubscription.interval) {
        clearInterval(state.currentGigExperiencesSubscription.interval);
      }
      // set up new subscription
      const idStr = `GIG-EXPERIENCES-${ID}`;
      state.currentGigExperiencesSubscription.subscription = idStr;
      socket.on(idStr, (data) => {
        // update actual date in state when new data arrives
        this.commit("addOrUpdateGigExperiencesData", {
          gigId: ID,
          bandId,
          values: data ? data.map(formatGigExperience) : [],
        });
        // record the last time the update occured
        state.currentGigExperiencesSubscription.lastUpdateTime = new Date();
      });
      // set up interval
      const localThis = this;
      const tipsInterval = setInterval(async () => {
        const now = new Date();
        const differenceinTime =
          now - state.currentGigExperiencesSubscription.lastUpdateTime;
        // if time since last update is less than 10 seconds, don't update
        if (differenceinTime >= 9 * 1000) {
          // fetch new data
          const {
            getExperiencesForGig,
          } = require("../services/controllers/experienceController");
          const experiences = await getExperiencesForGig(ID, bandId, true);
          // update state with new data
          localThis.commit("addOrUpdateGigExperiencesData", {
            gigId: ID,
            bandId,
            values: experiences,
          });
          // record the last time the update occured
          state.currentGigExperiencesSubscription.lastUpdateTime = new Date();
        }
      }, 10 * 1000);
      state.currentGigExperiencesSubscription.interval = tipsInterval;
    },
    // gig events
    addOrUpdateGigEventsData(state, value) {
      const { gigId } = value;
      // no current values at all so set it and create subscription
      if (!state.currentGigEvents) {
        state.currentGigEvents = value;
        this.commit("addgigEventsSubscription", gigId);
        // same gigId so just update the values
      } else if (state.currentGigEvents.gigId === gigId) {
        state.currentGigEvents = value;
      } else {
        // different gigId so set it and create subscription
        state.currentGigEvents = value;
        this.commit("addgigEventsSubscription", gigId);
      }
      state.gigEventsSubscription.lastUpdateTime = new Date();
    },
    addgigEventsSubscription(state, gigId) {
      // clear previous subscription and intervals
      if (state.gigEventsSubscription.subscription) {
        socket.off(state.gigEventsSubscription.subscription);
      }
      if (state.gigEventsSubscription.interval) {
        clearInterval(state.gigEventsSubscription.interval);
      }
      // set up new subscription
      const idStr = `GIGEVENTS-${gigId}`;
      state.gigEventsSubscription.subscription = idStr;
      socket.on(idStr, (data) => {
        // update actual date in state when new data arrives
        this.commit("addOrUpdateGigEventsData", {
          gigId,
          values: data || [],
        });
        // record the last time the update occured
        state.gigEventsSubscription.lastUpdateTime = new Date();
      });
      // set up interval
      const localThis = this;
      const tipsInterval = setInterval(async () => {
        const now = new Date();
        const differenceinTime =
          now - state.gigEventsSubscription.lastUpdateTime;
        // if time since last update is less than 10 seconds, don't update
        if (differenceinTime >= 9 * 1000) {
          // fetch new data
          const {
            getAllGigEvents,
          } = require("../services/controllers/eventController");
          const gigEvents = await getAllGigEvents(gigId, true);
          // update state with new data
          localThis.commit("addOrUpdateGigEventsData", {
            gigId,
            values: gigEvents,
          });
          // record the last time the update occured
          state.gigEventsSubscription.lastUpdateTime = new Date();
        }
      }, 10 * 1000);
      state.gigEventsSubscription.interval = tipsInterval;
    },
    // gig experience transactions
    addOrUpdateGigExperiencesTransactionsData(state, value) {
      const { gigId } = value;
      // no current values at all so set it and create subscription
      if (!state.currentGigExperiencesTransactions) {
        state.currentGigExperiencesTransactions = value;
        this.commit("addGigExperiencesTransactionsSubscription", gigId);
        // same gigId so just update the values
      } else if (state.currentGigExperiencesTransactions.gigId === gigId) {
        state.currentGigExperiencesTransactions = value;
      } else {
        // different gigId so set it and create subscription
        state.currentGigExperiencesTransactions = value;
        this.commit("addGigExperiencesTransactionsSubscription", gigId);
      }
      state.currentGigExperiencesTransactionsSubscription.lastUpdateTime =
        new Date();
    },
    addGigExperiencesTransactionsSubscription(state, ID) {
      // clear previous subscription and intervals
      if (state.currentGigExperiencesTransactionsSubscription.subscription) {
        socket.off(
          state.currentGigExperiencesTransactionsSubscription.subscription
        );
      }
      if (state.currentGigExperiencesTransactionsSubscription.interval) {
        clearInterval(
          state.currentGigExperiencesTransactionsSubscription.interval
        );
      }
      // set up new subscription
      const idStr = `EXPERIENCE-TRANSACTIONS-GROUPED-${ID}`;
      state.currentGigExperiencesTransactionsSubscription.subscription = idStr;
      socket.on(idStr, (data) => {
        // update actual date in state when new data arrives
        this.commit("addOrUpdateGigExperiencesTransactionsData", {
          gigId: ID,
          values: data ? formatExperienceTransactionsGroupedForGig(data) : null,
        });
        // record the last time the update occured
        state.currentGigExperiencesTransactionsSubscription.lastUpdateTime =
          new Date();
      });
      // set up interval
      const localThis = this;
      const tipsInterval = setInterval(async () => {
        const now = new Date();
        const differenceinTime =
          now -
          state.currentGigExperiencesTransactionsSubscription.lastUpdateTime;
        // if time since last update is less than 10 seconds, don't update
        if (differenceinTime >= 9 * 1000) {
          // fetch new data
          const {
            getGroupedExperienceTransactionsForGig,
          } = require("../services/controllers/experienceController");
          const groupedExperienceTransactionsForGig =
            await getGroupedExperienceTransactionsForGig({
              gigId: ID,
              forceRefresh: true,
            });
          // update state with new data
          localThis.commit("addOrUpdateGigExperiencesTransactionsData", {
            gigId: ID,
            values: groupedExperienceTransactionsForGig,
          });
          // record the last time the update occured
          state.currentGigExperiencesTransactionsSubscription.lastUpdateTime =
            new Date();
        }
      }, 10 * 1000);
      state.currentGigExperiencesTransactionsSubscription.interval =
        tipsInterval;
    },
    // merchandise
    addOrUpdateMerchandiseDataForBand(state, value) {
      const { bandId } = value;
      // no current values at all so set it and create subscription
      if (!state.currentMerchandiseForBand) {
        state.currentMerchandiseForBand = value;
        this.commit("addMerchandiseSubscriptionForBand", bandId);
        // same bandId so just update the values
      } else if (state.currentMerchandiseForBand.bandId === bandId) {
        state.currentMerchandiseForBand = value;
      } else {
        // different bandId so set it and create subscription
        state.currentMerchandiseForBand = value;
        this.commit("addMerchandiseSubscriptionForBand", bandId);
      }
      state.currentMerchandiseSubscription.lastUpdateTime = new Date();
    },
    addMerchandiseSubscriptionForBand(state, ID) {
      // clear previous subscription and intervals
      if (state.currentMerchandiseSubscription.subscription) {
        socket.off(state.currentMerchandiseSubscription.subscription);
      }
      if (state.currentMerchandiseSubscription.interval) {
        clearInterval(state.currentMerchandiseSubscription.interval);
      }
      // set up new subscription
      const idStr = `MERCHANDISE-BAND-${ID}`;
      state.currentMerchandiseSubscription.subscription = idStr;
      socket.on(idStr, (data) => {
        // update actual date in state when new data arrives
        this.commit("addOrUpdateMerchandiseDataForBand", {
          bandId: ID,
          values: data ? data.map(formatMerchandise) : null,
        });
        // record the last time the update occured
        state.currentMerchandiseSubscription.lastUpdateTime = new Date();
      });
      // set up interval
      const localThis = this;
      const tipsInterval = setInterval(async () => {
        const now = new Date();
        const differenceinTime =
          now - state.currentMerchandiseSubscription.lastUpdateTime;
        // if time since last update is less than 10 seconds, don't update
        if (differenceinTime >= 9 * 1000) {
          // fetch new data
          const {
            getMerchandise,
          } = require("../services/controllers/merchandiseController");
          const merchandise = await getMerchandise({
            bandId: ID,
            forceRefresh: true,
          });
          // update state with new data
          localThis.commit("addOrUpdateMerchandiseDataForBand", {
            bandId: ID,
            values: merchandise,
          });
          // record the last time the update occured
          state.currentMerchandiseSubscription.lastUpdateTime = new Date();
        }
      }, 10 * 1000);
      state.currentMerchandiseSubscription.interval = tipsInterval;
    },
    addOrUpdateMerchandiseData(state, value) {
      const { ID } = value;
      const objectKeys = Object.keys(value);
      if (!state.currentMerchandise) {
        state.currentMerchandise = value;
        this.commit("addMerchandiseSubscription", ID);
      } else if (state.currentMerchandise.ID === value.ID) {
        for (const key of objectKeys) {
          state.currentMerchandise[key] = value[key];
        }
      } else {
        state.currentMerchandise = value;
        this.commit("addMerchandiseSubscription", ID);
      }
    },
    addMerchandiseSubscription(state, ID) {
      if (state.merchandiseSubscription) {
        socket.off(state.merchandiseSubscription);
      }
      const idStr = `MERCHANDISE-${ID}`;
      state.merchandiseSubscription = idStr;
      socket.on(idStr, (data) => {
        this.commit("addOrUpdateMerchandiseData", formatMerchandise(data));
      });
    },
    // merchandise transactions
    addOrUpdateMerchandiseTransactionsData(state, value) {
      const { bandId } = value;
      // no current values at all so set it and create subscription
      if (!state.currentMerchandiseTransactions) {
        state.currentMerchandiseTransactions = value;
        this.commit("addMerchandiseTransactionsSubscription", bandId);
        // same bandId so just update the values
      } else if (state.currentMerchandiseTransactions.bandId === bandId) {
        state.currentMerchandiseTransactions = value;
      } else {
        // different bandId so set it and create subscription
        state.currentMerchandiseTransactions = value;
        this.commit("addMerchandiseTransactionsSubscription", bandId);
      }
      state.currentMerchandiseTransactionsSubscription.lastUpdateTime =
        new Date();
    },
    addMerchandiseTransactionsSubscription(state, ID) {
      // clear previous subscription and intervals
      if (state.currentMerchandiseTransactionsSubscription.subscription) {
        socket.off(
          state.currentMerchandiseTransactionsSubscription.subscription
        );
      }
      if (state.currentMerchandiseTransactionsSubscription.interval) {
        clearInterval(
          state.currentMerchandiseTransactionsSubscription.interval
        );
      }
      // set up new subscription
      const idStr = `MERCHANDISE-TRANSACTIONS-BAND-${ID}`;
      state.currentMerchandiseTransactionsSubscription.subscription = idStr;
      socket.on(idStr, (data) => {
        // update actual date in state when new data arrives
        this.commit("addOrUpdateMerchandiseTransactionsData", {
          bandId: ID,
          values: data ? formatMerchandiseTransaction(data) : null,
        });
        // record the last time the update occured
        state.currentMerchandiseTransactionsSubscription.lastUpdateTime =
          new Date();
      });
      // set up interval
      const localThis = this;
      const tipsInterval = setInterval(async () => {
        const now = new Date();
        const differenceinTime =
          now - state.currentMerchandiseTransactionsSubscription.lastUpdateTime;
        // if time since last update is less than 10 seconds, don't update
        if (differenceinTime >= 9 * 1000) {
          // fetch new data
          const {
            getMerchandiseTransactions,
          } = require("../services/controllers/merchandiseController");
          const merchandiseTransactions = await getMerchandiseTransactions({
            bandId: ID,
            forceRefresh: true,
          });
          // update state with new data
          localThis.commit("addOrUpdateMerchandiseTransactionsData", {
            bandId: ID,
            values: merchandiseTransactions,
          });
          // record the last time the update occured
          state.currentMerchandiseTransactionsSubscription.lastUpdateTime =
            new Date();
        }
      }, 10 * 1000);
      state.currentMerchandiseTransactionsSubscription.interval = tipsInterval;
    },
    // song battle transactions
    addOrUpdateSongBattleTransactionsData(state, value) {
      const { gigId } = value;
      // no current values at all so set it and create subscription
      if (!state.currentSongBattleTransactions) {
        state.currentSongBattleTransactions = value;
        this.commit("addSongBattleTransactionsSubscription", gigId);
        // same gigId so just update the values
      } else if (state.currentSongBattleTransactions.gigId === gigId) {
        state.currentSongBattleTransactions = value;
      } else {
        // different gigId so set it and create subscription
        state.currentSongBattleTransactions = value;
        this.commit("addSongBattleTransactionsSubscription", gigId);
      }
      state.currentSongBattleTransactionsSubscription.lastUpdateTime =
        new Date();
    },
    addSongBattleTransactionsSubscription(state, ID) {
      // clear previous subscription and intervals
      if (state.currentSongBattleTransactionsSubscription.subscription) {
        socket.off(
          state.currentSongBattleTransactionsSubscription.subscription
        );
      }
      if (state.currentSongBattleTransactionsSubscription.interval) {
        clearInterval(state.currentSongBattleTransactionsSubscription.interval);
      }
      // set up new subscription
      const idStr = `SONG-BATTLE-TRANSACTIONS-GROUPED-${ID}`;
      state.currentSongBattleTransactionsSubscription.subscription = idStr;
      socket.on(idStr, (data) => {
        // update actual date in state when new data arrives
        this.commit("addOrUpdateSongBattleTransactionsData", {
          gigId: ID,
          values: data || {},
        });
        // record the last time the update occured
        state.currentSongBattleTransactionsSubscription.lastUpdateTime =
          new Date();
      });
      // set up interval
      const localThis = this;
      const tipsInterval = setInterval(async () => {
        const now = new Date();
        const differenceinTime =
          now - state.currentSongBattleTransactionsSubscription.lastUpdateTime;
        // if time since last update is less than 10 seconds, don't update
        if (differenceinTime >= 9 * 1000) {
          // fetch new data
          const {
            getSongBattleTransactions,
          } = require("../services/controllers/songBattleController");
          const experiences = await getSongBattleTransactions({
            gigId: ID,
            forceRefresh: true,
          });
          // update state with new data
          localThis.commit("addOrUpdateSongBattleTransactionsData", {
            gigId: ID,
            values: experiences,
          });
          // record the last time the update occured
          state.currentSongBattleTransactionsSubscription.lastUpdateTime =
            new Date();
        }
      }, 10 * 1000);
      state.currentSongBattleTransactionsSubscription.interval = tipsInterval;
    },
    // livecast state,
    addOrUpdateLivecastStateData(state, value) {
      const { gigId } = value;
      // no current values at all so set it and create subscription
      if (!state.currentLivecastState) {
        state.currentLivecastState = value;
        this.commit("addLivecastStateSubscription", gigId);
        // same gigId so just update the values
      } else if (state.currentLivecastState.gigId === gigId) {
        state.currentLivecastState = value;
      } else {
        // different gigId so set it and create subscription
        state.currentLivecastState = value;
        this.commit("addLivecastStateSubscription", gigId);
      }
      state.currentLivecastStateSubscription.lastUpdateTime = new Date();
    },
    addLivecastStateSubscription(state, gigId) {
      // clear previous subscription and intervals
      if (state.currentLivecastStateSubscription.subscription) {
        socket.off(state.currentLivecastStateSubscription.subscription);
      }
      if (state.currentLivecastStateSubscription.interval) {
        clearInterval(state.currentLivecastStateSubscription.interval);
      }
      // set up new subscription
      const idStr = `LIVECAST-${gigId}`;
      state.currentLivecastStateSubscription.subscription = idStr;
      socket.on(idStr, (data) => {
        // update actual date in state when new data arrives
        this.commit("addOrUpdateLivecastStateData", {
          gigId,
          value: data,
        });
        // record the last time the update occured
        state.currentLivecastStateSubscription.lastUpdateTime = new Date();
      });
      // set up interval
      const localThis = this;
      const tipsInterval = setInterval(async () => {
        const now = new Date();
        const differenceinTime =
          now - state.currentLivecastStateSubscription.lastUpdateTime;
        // if time since last update is less than 10 seconds, don't update
        if (differenceinTime >= 9 * 1000) {
          // fetch new data
          const {
            getLivecastState,
          } = require("../services/controllers/livecastController");
          const livecastState = await getLivecastState(gigId, true);
          // update state with new data
          localThis.commit("addOrUpdateLivecastStateData", {
            gigId,
            value: livecastState,
          });
          // record the last time the update occured
          state.currentLivecastStateSubscription.lastUpdateTime = new Date();
        }
      }, 10 * 1000);
      state.currentLivecastStateSubscription.interval = tipsInterval;
    },
    // other
    setCurrentFirebaseUser(state, currentFirebaseUser) {
      state.currentFirebaseUser = currentFirebaseUser;
      if (!currentFirebaseUser || currentFirebaseUser === null) {
        state.currentlyLoggedInBand = undefined;
        persistentStore.commit("setCurrentlyLoggedInBand", null);
      } else if (!state.currentlyLoggedInBand) {
        state.currentlyLoggedInBand =
          persistentStore.getters.currentlyLoggedInBand;
      }
    },
    setCurrentlyLoggedInBand(state, band) {
      state.currentlyLoggedInBand = band;
      persistentStore.commit("setCurrentlyLoggedInBand", band);
    },
    setDisableProfileTextNotificationsStep(state) {
      state.disableProfileTextNotificationsStep = true;
      persistentStore.commit("setDisableProfileTextNotificationsStep", true);
    },
    setSelectedCurrency(state, currency) {
      state.selectedCurrency = currency;
      persistentStore.commit("setSelectedCurrency", currency);
    },
    setUserVotingForGigs(state, gigStats) {
      state.userVotingForGigs = gigStats;
    },
    reportTipAtGig(state, gigId) {
      if (!state.userVotingForGigs[gigId]) {
        state.userVotingForGigs[gigId] = {
          numVotes: 1,
        };
      } else {
        state.userVotingForGigs[gigId].numVotes += 1;
      }
      persistentStore.commit("reportTipAtGig", gigId);
    },
  },
});

export { nonPersistentStore, persistentStore };
export default nonPersistentStore;
